.cover {
    display: flex;
    flex-direction: column;

    &__img {
        width: 100%;
        height: 500px;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        &--nodefault {
            height: 0;
        }
    }

    &__container {
        padding: 30px 0;
    }

    &__title {
        color: $color-text;
        text-align: center;
        margin: 0;
    }

    &__text {
        font-size: 18px;
        line-height: 1.3em;
        color: $color-text;
        text-align: center;
        margin-top: 17.5px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .cover {    
            &__img {
                height: 420px;
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .cover {    
            &__img {
                height: 380px;
            }
        }
    }


// 640
@media screen and (max-width: $small) {
    .cover {    
        &__img {
            height: 320px;
        }
    }
}