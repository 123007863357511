.footer {
    position: relative;
    z-index: 2;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &-top {
        background-color: $color-third;

        &__container {
            display: flex;
            align-items: flex-start;
            padding: 80px 0 80px 25px;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 16px;
    }

    &__logo {
        svg {
            width: 192px;
            height: 69px;
            fill: $color-white;
        }
    }

    &__contact {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 8px;
        font-size: $font-size--text-small;
        font-weight: $font-weight-bold;
        color: $color-third;
        text-transform: uppercase;
        background-color: white;
        padding: 0 27px;
        border: 2px solid $color-white;
        border-radius: 30px;
        transition: $duration;

        svg {
            width: 26px;
            height: 26px;
            fill: $color-third;
            transition: $duration;
        }

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-dark;
            border-color: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }

    &__mairie {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 155px;
    }

    &__title {
        font-family: $font-family;
        font-size: $font-size--4;
        font-weight: $font-weight-semibold;
        color: $color-white;
        margin: 0;
    }

    &__text {
        line-height: 1.3em;
        font-weight: $font-weight-normal;
        color: $color-white;
        margin-top: 16px;
    }

    &__tel {
        display: block;
        line-height: 1.3em;
        font-weight: $font-weight-bold;
        color: $color-white;
        margin-top: 25px;
        transition: $duration;

        &:hover,
        &:focus {
            color: $color-second;
        }
    }

    &__horaires {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 210px;
    }

    &-bottom {
        background-color: $color-white;
        
        &__container {
            padding: 30px 0;
        }
    }

    &__menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 30px;
    }

    &__effect {
        width: 18px;
        height: 1px;
        background-color: $color-text;
    }

    &__link a {
        font-size: $font-size--text;
        font-weight: $font-weight-normal;
        color: $color-text;
        padding: 5px 0;

        &:hover,
        &:focus {
            color: $color-third;
        }
    }
}


// 1200
@media screen and (max-width: $large) {
    .footer {        
        &-top {    
            &__container {
                padding: 80px 0;
            }
        }
    
        &__contact {
            padding: 0 28px;
        }
    
        &__mairie {
            margin-left: 98px;
        }
    
        &__horaires {
            margin-left: 128px;
        }

        &__menu {
            column-gap: 10px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {        
        &-top {    
            &__container {
                flex-direction: column;
                align-items: center;
                padding: 60px 0 54px;
            }
        }

        &__links {
            align-items: center;
            row-gap: 15px;
        }

        &__mairie {
            align-items: center;
            margin: 37px 0 0 0;
        }

        &__title,
        &__text {
            text-align: center;
        }

        &__text {
            line-height: 1.35em;
            margin-top: 20px;
        }

        &__tel {
            margin-top: 23px;
        }
    
        &__horaires {
            align-items: center;
            margin: 38px 0 0 0;
        }

        &-bottom {
            &__container {
                padding: 30px 0;
            }
        }

        &__menu {
            width: 500px;
            flex-wrap: wrap;
            row-gap: 35px;
            margin: 0 auto;

            .footer__effect:nth-child(6) {
                display: none;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {        
        &-top {    
            &__container {
                padding: 60px 0;
            }
        }

        &__tel {
            margin-top: 20px;
        }

        &-bottom {
            &__container {
                padding: 30px 0;
            }
        }

        &__menu {
            width: 100%;
            column-gap: 30px;

            .footer__effect {
                &:nth-child(6) {
                    display: block;
                }

                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(8) {
                    display: none;
                }
            }
        }
    }
}