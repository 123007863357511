body {
    &.admin-bar {
        .header--fixed .header__container {
            top: 32px;
        }
    }

    &.sticky {
        #content {
            margin-top: 100px;
        }
    }
}



//======================================================================================================
// Fil d'infos
//======================================================================================================
.fil-infos {
    position: relative;
    background-color: $color-third;
    z-index: 2;

    &__container {
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
    }

    &__title {
        flex-shrink: 0;
        font-size: 28px;
        font-weight: $font-weight-bold;
        color: $color-white;
    }

    &__content {
        flex: 1;
        max-width: 100%;
    }

    .swiper-wrapper {
        align-items: center;
    }

    &__text {
        font-size: 22px;
        color: $color-white;
        margin-top: 5px;
        transition: $duration;

        &:hover,
        &:focus {
            color: $color-fourth;
        }
    }

    &__pagination {
        flex-shrink: 0;
        display: flex;
        column-gap: 20px;
    }

    &__prev, 
    &__next {
        width: 40px;
        height: 40px;
        background: $color-white;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: $border-radius--round;
        transition: $duration;

        svg {
            width: 100%;
            height: 100%;
            fill: $color-third;
            transition: $duration;
        }

        &:hover,
        &:focus {
            background-color: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }
}



.header {
    width: 100%;
    position: relative;
    z-index: 990;

    &__container {
        width: 100%;
        position: relative;
        background-color: $color-white;
        box-shadow: $shadow-header;
    }

    &__content {
        height: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: $duration;
    }

    &__logo {
        width: 80px;
        height: 80px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__identity {
        display: none;
    }

    &--fixed {
        .header {
            &__container {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 3;
            }

            &__content {
                height: 100px;
            }
        }

        #nav-main {
            .menu__nav__list {
                .nav-main-link {
                    height: 100px;

                    &::after {
                        bottom: 25px;
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

.tools {
    display: flex;
    align-items: center;
    column-gap: 20px;

    &__item {
        width: 40px;
        height: 40px;
        background-color: $color-third;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: $border-radius--round;
        transition: $duration;

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        svg {
            width: 100%;
            height: 100%;
            fill: none;
            transition: $duration;

            .st0 {
                stroke: $color-white;
            }

            .st1 {
                fill: $color-white;
            }
        }

        &:hover,
        &:focus {
            background-color: $color-dark;

            svg {
                fill: none;
            }
        }
    }

    #accessconfig {
        width: 40px;
        height: 40px;
    }

    #a42-ac-button {
        display: none;
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        body {        
            &.sticky {
                #content {
                    margin-top: 100px;
                }
            }
        }
        
        
        
        .fil-infos {        
            &__container {
                column-gap: 20px;
            }
        }
    }


    // 960
    @media screen and (max-width: $medium) {       
        .fil-infos {     
            padding: 23px 0;

            &__container {
                height: auto;
                flex-direction: column;
                align-items: center;
            }

            &__content {
                margin-top: 10px;
            }

            &__text {
                text-align: center;
                margin-top: 0;
            }

            &__pagination {
                margin-top: 14px;
            }
        }
        

        .privacy {
            &__container {
                flex-wrap: wrap;
            }

            &__button {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }


    // 640
    @media screen and (max-width: $small) {
        .fil-infos {     
            padding: 22px 0 20px;

            &__content {
                margin-top: 8px;
            }

            &__text {
                line-height: 1.2em;
            }

            &__pagination {
                margin-top: 18px;
            }
        }



        .header {
            &__content {
                height: auto;
                flex-wrap: wrap;
                row-gap: 20px;
                padding: 20px 0 30px;
            }

            &__home {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }

        .tools {
            column-gap: 10px;
        }
    }