.breadcrumb {
    background-color: #7F9BC3;
    padding: 10px 0;

    ul  {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
    }

    li {
        padding: 5px;

        &:first-child {
            padding-left: 0;
        }

        a,
        span {
            display: block;
            text-align: center;
            transition: $duration;
        }

        a {
            &:hover,
            &:focus {
                color: $color-white;
            }
        }
    }

    svg {
        width: 24px;
        fill: $color-main;
    }
}