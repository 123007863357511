#nav-main {
    .menu__nav__list {
        display: flex;
    } 

    .nav-main-item {
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
            width: 282px;
        }

        &:nth-child(2) {
            width: 280px;
        }

        &:last-child {
            width: 225px;
        }

        &.menu-item-has-children:hover,
        &.menu-item-has-children:focus,  
        &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        .nav-main-link {
            width: 100%;
            height: 120px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            font-weight: $font-weight-semibold;
            color: $color-text;
            text-transform: uppercase;
            padding: 0;
            transition: $duration;

            &::after {
                content: '';
                width: 35px;
                height: 1.5px;
                position: absolute;
                left: 50%;
                bottom: 35px;
                transform: translateX(-50%);
                background-color: $color-main;
                z-index: 1;
                transition: $duration;
            }
        }

        .sub-menu {
            display: none;
            width: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            background: $color-fourth;
            padding: 30px 15px;

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, 
            .nav-grandchild-item {
                width: 100%;
                display: inline-flex;
                line-height: initial;
                margin: -1px; // Fix space beetween inline-flex

                a {
                    width: 100%;
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-size: $font-size--text;
                    font-weight: $font-weight-bold;
                    color: $color-text;
                    padding: 15px 30px 15px 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex

                    &::before {
                        content: "";
                        width: 60px;
                        height: 1px;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        background: $color-dark;
                        opacity: 0.5;
                        transition: $duration;
                    }

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 0;
                        color: $color-text;
                        opacity: .5;
                        transition: $duration;
                    }

                    &:hover,
                    &:focus {
                        padding-left: 10px;

                        &::before {
                            width: 120px;
                            left: 10px;
                            opacity: 1;
                        }

                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    font-size: $font-size--text;
                    font-weight: $font-weight;
                    padding: 15px 0 15px 30px;

                    &::before {
                        display: none;
                    }

                    &::after {
                        right: unset;
                        left: 0;
                    }

                    &:hover,
                    &:before {
                        padding-left: 37.5px;
                    }
                } 
            }
        }

        &:hover,
        &:focus {
            cursor: pointer;

            .nav-main-link::after {
                width: 75px;
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    position: relative;
    display: none;
    align-items: center;
    font-size: 22px;
    font-weight: $font-weight-semibold; 
    color: $color-text;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;

    &::after {
        content: '';
        width: 35px;
        height: 1px;
        position: absolute;
        left: 50%;
        bottom: -10px;
        transform: translateX(-50%);
        background-color: $color-main;
        z-index: 1;
    }
}


//=================================================================================================
// Responsive
//=================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        #nav-main {        
            .nav-main-item {
                &:first-child {
                    width: 250px;
                }
        
                &:nth-child(2) {
                    width: 250px;
                }
        
                &:last-child {
                    width: 195px;
                }
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        #nav-main {
            margin-left: 18px;

            .menu__nav__list {
                width: 100%;
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 100%;
                left: 0;
                background: $color-third;
            }

            .nav-main-item {
                width: 100% !important;
                flex-direction: column;

                &:first-child,
                &:nth-child(2),
                &:last-child {
                    width: 100%;
                }

                .nav-main-link {
                    height: auto;
                    position: relative;
                    color: $color-white;
                    padding: 20px 15px;

                    &::after {
                        content: '';
                        width: 35px;
                        height: 1px;
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transform: translateX(-50%);
                        background-color: $color-white;
                        z-index: 1;
                    }
                }

                &:last-child {
                    .nav-main-link {
                        &::after {
                            width: 0;
                        }
                    }
                }


                &.menu-item-has-children:hover, 
                &.menu-item-has-children:focus,
                &.menu-item-has-children:focus-within {
                    .sub-menu {
                        display: none;
                    }
                }

                &.active,
                &.menu-item-has-children.active {
                    .nav-main-link::after {
                        width: 0;
                        background-color: transparent;
                    }

                    .sub-menu {
                        position: relative;
                        display: flex;

                        .nav-drop {
                            column-count: 1;
                        }
                    }
                }

            }

            // Responsive button
            .nav-main_button {
                ~ .menu {
                    clear: both;
                    transform: scaleY(0);
                    transform-origin: 50% 0;
                    transition:transform .3s ease;
                }

                &:checked {
                    ~ .menu {
                        transform: scaleY(1);
                    }

                    ~ .nav-main_icon {
                        .navicon {
                            background-color: transparent;
                            
                            &:before {
                                transform: rotate(-45deg);
                            }

                            &:after {
                                transform: rotate(45deg);
                            }
                        }

                        &:not(.steps) {
                            .navicon {
                                &:before,
                                &:after {
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }

            // Responsive button label
            .nav-main_icon {
                display: flex;
            }
        }
    }


    
    // 640
    @media screen and (max-width: $small) {
        #nav-main {
            margin-left: 47px;
        }
    }