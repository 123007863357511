//======================================================================================================
// Général
//======================================================================================================
.front-page,
.footer {
    * {
        box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4 {
        padding: 0;
        margin: 0;
        border: 0;
    }
}

.site {
    &-name {
        position: absolute;
        top: 0;
        left: calc(((100% - 1200px) / 2) - 115px);
        z-index: 2;

        &__bg {
            svg {
                width: 885px;
                height: 780px;
                margin-top: -480px;
                margin-left: -123px;
                fill: $color-third;
                opacity: .8;
            }
        }

        &__logo {
            position: absolute;
            top: 60px;
            left: calc(((100% - 1200px) / 2) + 350px);

            svg {
                width: 370px;
                height: 130px;
                fill: $color-white;
            }
        }
    }
}

.home {    
    &__title {
        font-family: $font-family--special;
        font-size: 50px;
        color: $color-text;
    }

    &__button {
        width: 250px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size--text-small  ;
        font-weight: $font-weight-bold;
        color: $color-text;
        text-transform: uppercase;
        background-color: transparent;
        padding: 0 10px;
        border: 2px solid $color-text;
        border-radius: 30px;
        transition: $duration;

        &:hover,
        &:focus {
            color: $color-white;
            background: $color-main;
        }
    }
}



//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    .swiper-slide {
        height: 100% !important;
    }

    .slideshow {
        width: 100%;
        position: relative;

        img, 
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        &__item {
            width: 100%;
            height: 645px;
            position: relative;
            display: flex;

            &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.235417) 58.63%, rgba(0, 0, 0, 0.4) 100%);
                z-index: 1;
            }

            &[href] {
                &:hover,
                &:focus {
                    .slideshow {
                        
                    }
                }
            }
        }

        &__img {
            height: 100%;
            background-color: $color-bg--image;
        }

        &__container {
            width: 100%;
            position: absolute;
            left: 50%;
            bottom: 40px;
            transform: translateX(-50%);
            display: flex;
            justify-content: flex-end;  
            z-index: 2;
        }

        &__content {
            max-width: 350px;
        }

        &__title {
            font-family: $font-family;
            font-size: 25px;
            font-weight: $font-weight-semibold;
            color: $color-white;
            text-transform: none;
            text-align: right;
            text-shadow: 0px 1px 15px rgba(0, 0, 0, 0.7);
            margin: 0;
            transition: $duration;

            &::after {
                display: none;
            }
        }

        &__text {
            font-size: $font-size--5;
            line-height: 1.3em;
            color: $color-white;
            text-align: right;
            text-shadow: 0px 1px 15px rgba(0, 0, 0, 0.7);
            margin-top: 10px;
            transition: $duration;
        }

        &-pagination {
            width: 100%;
            position: absolute;
            left: 50%;
            bottom: 40px;
            transform: translateX(-50%);
            display: flex;
            column-gap: 20px;
            z-index: 3;

            &__prev, 
            &__next {
                width: 40px;
                height: 40px;
                background: $color-white;
                padding: 0;
                margin: 0;
                border: 0;
                border-radius: $border-radius--round;
                transition: $duration;

                svg {
                    width: 100%;
                    height: 100%;
                    fill: $color-third;
                    transition: $duration;
                }

                &:hover,
                &:focus {
                    background-color: $color-dark;

                    svg {
                        fill: $color-white;
                    }
                }
            }
        }

        &--video {
            img {
                display: none;
            }
        }
    }
}



//======================================================================================================
// Quick Access
//======================================================================================================
.qa {
    position: relative;
    margin-top: 60px;

    &__items {
        display: flex;
        justify-content: center;
        column-gap: 100px;
    }

    &-item {
        width: 120px;
        height: 180px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        row-gap: 20px;

        svg {
            width: 90px;
            height: 90px;
            fill: $color-main;
            transition: $duration;
        }

        &__title {
            font-family: $font-family;
            font-size: $font-size--5 ;
            line-height: 1.4em;
            font-weight: $font-weight-semibold;
            color: $color-text;
            text-transform: uppercase;
            text-align: center;
            transition: $duration;

            &::after {
                display: none;
            }
        }

        &::after {
            content: '';
            width: 35px;
            height: 1px;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            background-color: $color-main;
            z-index: 1;
            transition: $duration;
        }

        &:hover,
        &:focus {
            &::after {
                width: 70px;
            } 

            .qa-item__title {
                color: $color-main;
            }
        }
    }
}



//======================================================================================================
// Agenda
//======================================================================================================
.ag {
    margin-top: 83px;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__items {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 52px;
    }

    &-item {
        width: 570px;
        position: relative;

        &__img {
            width: 100%;
            height: 400px;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__dates {
            position: absolute;
            top: 22px;
            left: 30px;
            font-size: 26px;
            font-weight: $font-weight-semibold;
            color: $color-text;
            text-transform: uppercase;
            z-index: 1;
            transition: $duration;

            &::before {
                content: '';
                width: 320px;
                height: 320px;
                position: absolute;
                left: -115px;
                bottom: -52px;
                background-color: $color-second;
                border-radius: $border-radius--round;
                z-index: -1;
                transition: $duration;
            }

            &--double {
                &::before {
                    left: -65px;
                }
            }
        }

        &__category {
            position: absolute;
            right: 10px;
            bottom: 10px;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            color: $color-text;
            text-transform: uppercase;
            background-color: $color-second;
            padding: 15px 30px;
            border-radius: 30px;
            transition: $duration;
        }

        &__content {
            max-width: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;
            margin: 0 auto;
        }

        &__title {
            position: relative;
            font-family: $font-family;
            line-height: 1em;
            font-weight: $font-weight-semibold;
            color: $color-text;
            text-align: center;
            padding-bottom: 20px !important;

            &::after {
                content: '';
                width: 55px;
                height: 1px;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                background-color: $color-text;
                z-index: 1;
                transition: $duration;
            }
        }

        &__text {
            font-size: 18px;
            color: $color-main;
            font-style: italic;
            text-transform: uppercase;
            margin-top: 23px;
            transition: $duration;
        }

        &:hover,
        &:focus {
            .ag-item {
                &__dates {
                    color: $color-white;

                    &::before {
                        background-color: $color-main;
                    }
                }

                &__category {
                    color: $color-white;
                    background-color: $color-main;
                }

                &__title {
                    &::after {
                        width: 95px;
                    }
                }
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        column-gap: 30px;
        margin-top: 22px;
    }

    &__propose:hover,
    &__propose:focus {
        color: $color-white;
        background-color: $color-dark;
    }

    &__all {
        color: $color-main;
        border-color: $color-main;
    }
}



//======================================================================================================
// Actualités
//======================================================================================================
.ac {
    background: $color-fourth;
    padding: 60px 0;
    margin-top: 80px;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__header {
        display: flex;
        align-items: center;
        column-gap: 60px;
    }

    &__prev, 
    &__next {
        width: 40px;
        height: 40px;
        background: $color-dark;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: $border-radius--round;

        svg {
            width: 100%;
            height: 100%;
            fill: $color-white;
        }
    }

    &__items {
        margin-top: 55px;
    }

    &-item {
        width: 370px !important;

        &__img {
            width: 100%;
            height: 400px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__content {
            width: 100%;
            padding: 20px 0;
        }

        &__title {
            position: relative;
            font-family: $font-family;
            line-height: 1em;
            font-weight: $font-weight-semibold;
            color: $color-text;
            text-align: center;
            padding-bottom: 20px !important;
            transition: $duration;

            &::after {
                content: '';
                width: 55px;
                height: 1px;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                background-color: $color-text;
                z-index: 1;
                transition: $duration;
            }
        }

        &__text {
            font-size: 18px;
            line-height: 1.35em;
            font-weight: $font-weight-normal;
            color: $color-text;
            text-align: center;
            margin-top: 20px;
        }

        &:hover,
        &:focus {
            .ac-item {
                &__title {
                    color: $color-main;

                    &::after {
                        width: 95px;
                    }
                }
            }
        }
    }

    &__button {
        margin-top: 20px;

        &:hover,
        &:focus {
            background-color: $color-dark;
        }
    }
}



//======================================================================================================
// Kiosque + Social Wall
//======================================================================================================
.kiosque-wall {
    position: relative;
    padding: 83px 0 75px;

    &__effect {
        width: 920px;
        height: 800px;
        position: absolute;
        top: 50%;
        right: calc(((100% - 1200px) / 2) - 520px);
        z-index: -1;

        svg {
            width: 100%;
            height: 100%;
            fill: $color-second;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }
}


//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {
    width: 570px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-item {
        display: flex;
        column-gap: 30px;
        margin-top: 32px;

        &__image {
            width: 270px;
            height: 400px;
            flex-shrink: 0;
            background-color: $color-bg--image;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) $color-bg--image no-repeat center;
            }
        }
    
        &__content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__title {
            font-family: $font-family;
            line-height: 1em;
            font-weight: $font-weight-semibold;
            color: $color-text;
            text-align: left;

            &::after {
                display: none;
            }
        }

        &__text {
            line-height: 1.3em;
            margin-top: 20px;

            span {
                text-transform: uppercase;
            }
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 15px;
            margin-top: 18px;
        }

        &__button {   
            display: flex;
            align-items: center;
            column-gap: 15px;
    
            &__icon {
                width: 40px;
                height: 40px;
                background-color: $color-dark;
                border-radius: $border-radius--round;
                transition: $duration;
    
                svg {
                    width: 100%;
                    height: 100%;
                    fill: $color-white;
                }
            }
    
            &__text {
                line-height: 1.25em;
                font-weight: $font-weight-normal;
                color: $color-text;
                text-transform: uppercase;
                font-style: italic;
            }

            &--listen {
                .kiosque-item__button__icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        width: 26px;
                        height: 26px;
                    }
                }
            }
    
            &:hover,
            &:focus {
                .kiosque-item__button {
                    &__icon {
                        background-color: $color-main;
                    }

                    &__text {
                        color: $color-main;
                    }
                }
            }
        }
    }

    &__button {
        color: $color-main;
        border-color: $color-main;
        margin: auto 0 0;
    }
}


//======================================================================================================
// Social Wall
//======================================================================================================
.social-wall {
    width: 570px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .container {
        position: relative;

        &::after {
            content: '';
            width: 810px;
            height: 810px;
            position: absolute;
            top: 225px;
            left: 25%;
            background-color: $color-third;
            border-radius: $border-radius--round;
            z-index: -1;
        }
    }

    &__title {
        font-size: 44px;
        line-height: 1.1em;
        color: $color-text;
        text-transform: uppercase;

        span {
            display: block;
            font-size: 24px;
            color: $color-main;
            margin-top: -5px;
        }
    }

    &__items {
        margin-top: 20px;
    }

    #ff-stream-1 {
        width: 100% !important;
        min-height: auto !important;
        background-color: transparent !important;
        padding: 0 !important;
    
        .ff-stream-wrapper {
            width: calc(100% + 30px) !important;
            height: 400px !important;
            position: relative !important;
            left: -10px !important;
            margin: 32px 0 0 !important;
        }
    
        .ff-item {
            height: 400px !important;

            .picture-item__inner {
                height: 100% !important;
                display: flex !important;
                flex-direction: column !important;
                box-shadow: $shadow !important;
            }

            .ff-item-cont {
                flex: 1 !important;
                max-height: calc(100% - 33px) !important;
                display: flex !important;
                flex-direction: column !important;
            }

            .ff-img-holder {
                height: 220px !important;
    
                img {
                    width: 100% !important;
                    height: 100% !important;
                    object-fit: cover !important;
                }
            }

            .ff-content {
                height: auto !important;
                flex: 1 !important;
                margin: 3.5% 7% !important;

                a {
                    color: $color-text !important;
                }
            }

            .ff-item-meta {
                margin: 3.5% 7% 7% !important;
            }

            .ff-item-bar {
                height: 33px !important;
                padding: 7px !important;
                border-top-color: $color-dark !important;
            }
        }
    
        .ff-loadmore-wrapper {
            display: none !important;
        }

        .ff-facebook .ff-icon-inner {
            background-color: #086AB8 !important;
        }

        .ff-instagram  {
            .ff-icon-inner {
                background-color: #9B51E0 !important;
            }

            a {
                color: $color-text !important;
            }
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .site {
            &-name {     
                left: calc(((100% - 960px) / 2) - 85px);

                &__bg {
                    svg {
                        margin-top: -500px;
                        margin-left: -170px;
                    }
                }
        
                &__logo {
                    top: 50px;
                    left: calc(((100% - 960px) / 2) + 215px);
                }
            }
        }



        .home-slideshow {        
            .slideshow {
                &__item {
                    height: 515px;
                }
        
                &__container,
                &-pagination {
                    bottom: 30px;
                }
        
                &__text {
                    margin-top: 10px;
                }
            }
        }



        //======================================================================================================
        // Agenda
        //======================================================================================================
        .ag {
            margin-top: 63px;

            &-item {
                width: 460px;

                &__img {
                    height: 320px;
                }

                &__content {
                    max-width: 100%;
                }

                &__title {
                    font-size: 25px;
                    line-height: 1.2em;
                }
            }

            &__buttons {
                column-gap: 20px;
            }
        }



        //======================================================================================================
        // Actualités
        //======================================================================================================
        .ac {
            padding: 62px 0;

            &__items {
                margin-top: 53px;
            }

            &-item {
                width: 300px !important;

                &__img {
                    height: 325px;
                }

                &__title {
                    font-size: 25px;
                    line-height: 1.2em;
                }
            }

            &__button {
                margin-top: 22px;
            }
        }



        //======================================================================================================
        // Kiosque + Social Wall
        //======================================================================================================
        .kiosque-wall {
            padding: 80px 0 75px;

            &__effect {
                right: calc(((100% - 960px) / 2) - 560px);
            }
        }


        //======================================================================================================
        // Kiosque
        //======================================================================================================
        .kiosque {
            width: 470px;

            &-item {
                column-gap: 20px;

                &__image {
                    width: 220px;
                    height: 330px;
                }

                &__title {
                    font-size: 25px;
                    line-height: 1.12em;
                }

                &__text {
                    font-size: $font-size--text-small;
                    margin-top: 16px;
                }

                &__buttons {
                    row-gap: 10px;
                    margin-top: 17px;
                }

                &__button {               
                    &__icon {
                        width: 30px;
                        height: 30px;
                    }

                    &--listen {
                        .kiosque-item__button__icon {
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }

            &__button {
                width: 220px;
            }
        }



        //======================================================================================================
        // Social Wall
        //======================================================================================================
        .social-wall {
            width: 470px;

            #ff-stream-1 {            
                .ff-stream-wrapper {
                    height: 330px !important;
                    margin: 35px 0 0 !important;
                }
            
                .ff-item {
                    height: 330px !important;
        
                    .ff-img-holder {
                        height: 180px !important;
                    }
                }
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .site {
            &-name {      
                left: calc(((100% - 640px) / 2) - 100px);  

                &__bg {
                    svg {
                        width: 700px;
                        height: 620px;
                        margin-top: -420px;
                        margin-left: -95px;
                    }
                }
        
                &__logo {
                    top: 30px;
                    left: calc(((100% - 640px) / 2) + 125px);

                    svg {
                        width: 290px;
                        height: 100px;
                    }
                }
            }
        }



        .home-slideshow {        
            .slideshow {
                &__item {
                    height: 415px;
                }

                &__container,
                &-pagination {
                    bottom: 35px;
                }

                &__content {
                    max-width: 300px;
                }

                &__title {
                    font-size: 22px;
                }
        
                &__text {
                    font-size: $font-size--text;
                    line-height: 1.35em;
                }

                &--video {
                    video {
                        display: none;
                    }

                    img {
                        display: block;
                    }
                }
            }
        }



        //======================================================================================================
        // Quick Access
        //======================================================================================================
        .qa {
            margin-top: 55px;

            &__items {
                width: 340px;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                row-gap: 30px;
                column-gap: 0;
                margin: 0 auto;
            }
        }



        //======================================================================================================
        // Agenda
        //======================================================================================================
        .ag {
            &__items {
                margin-top: 32px;
            }

            &-item {
                width: 300px;

                &__img {
                    height: 210px;
                }

                &__dates {
                    top: 15px;
                    left: 15px;
                    font-size: 22px;

                    &::before {
                        left: -155px;
                        bottom: -35px;
                    }

                    &--double {
                        &::before {
                            left: -80px;
                        }
                    }
                }

                &__category {
                    padding: 5px 30px;
                }
            }
        }
        


        //======================================================================================================
        // Actualités
        //======================================================================================================
        .ac {
            &__items {
                margin-top: 33px;
            }

            &__button {
                margin-top: 19px;
            }
        }



        //======================================================================================================
        // Kiosque + Social Wall
        //======================================================================================================
        .kiosque-wall {
            padding: 85px 0 75px;

            &__effect {
                top: 75%;
                right: calc(((100% - 640px) / 2) - 505px);
            }

            &__container {
                flex-direction: column;
                align-items: center;
                row-gap: 83px;
            }
        }


        //======================================================================================================
        // Kiosque
        //======================================================================================================
        .kiosque {
            width: 500px;
            align-items: center;

            &-item {
                margin-top: 31px;
            }

            &__button {
                width: 250px;
            }
        }



        //======================================================================================================
        // Social Wall
        //======================================================================================================
        .social-wall {
            width: 100%;
            align-items: center;

            #ff-stream-1 {            
                .ff-stream-wrapper {
                    width: calc(100% + 40px) !important;
                    height: 425px !important;
                    left: -20px !important;
                    margin: 32px 0 0 !important;
                }
            
                .ff-item {
                    height: 425px !important;
        
                    .ff-img-holder {
                        height: 245px !important;
                    }
                }
            }
        }
    }


    // 640
    @media screen and (max-width: $small) {
        .home {    
            &__title {
                font-size: 42px;
            }
        }



        .site {
            &-name {    
                left: calc(((100% - 320px) / 2) - 110px);  

                &__bg {
                    svg {
                        width: 570px;
                        height: 500px;
                        margin-top: -344px;
                        margin-left: -78px;
                    }
                }
        
                &__logo {
                    top: 20px;
                    left: calc(((100% - 320px) / 2) + 35px);

                    svg {
                        width: 200px;
                        height: 70px;
                    }
                }
            }
        }



        .home-slideshow {        
            .slideshow {
                &__item {
                    height: auto;
                    flex-direction: column;
                    row-gap: 78px;

                    &::after {
                        display: none;
                    }
                }

                &__img {
                    height: 300px;
                }

                &__container {
                    position: relative;
                    left: unset;
                    bottom: unset;
                    transform: unset;
                    justify-content: center;
                }

                &__title,
                &__text {
                    color: $color-text;
                    text-align: center;
                    text-shadow: unset;
                }

                &__text {
                    margin-top: 6px;
                }

                &-pagination {
                    top: 320px;
                    bottom: unset;
                    justify-content: center;

                    &__prev,
                    &__next {
                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                    }
                }

                &--video {
                    .slideshow__item {
                        row-gap: 20px;
                    }
                }
            }
        }



        //======================================================================================================
        // Quick Access
        //======================================================================================================
        .qa {
            margin-top: 65px;

            &__items {
                width: 100%;
                row-gap: 40px;
            }
        }



        //======================================================================================================
        // Agenda
        //======================================================================================================
        .ag {
            margin-top: 78px;

            &__items {
                flex-direction: column;
                row-gap: 40px;
                margin-top: 36px;
            }

            &-item {
                &__content {
                    padding-bottom: 0;
                }
            }

            &__buttons {
                flex-direction: column;
                row-gap: 20px;
                margin-top: 42px;
            }
        }



        //======================================================================================================
        // Actualités
        //======================================================================================================
        .ac {
            padding: 69px 0 60px;

            &__header {
                flex-wrap: wrap;
                justify-content: center;
                row-gap: 15px;
                column-gap: 20px;
            }

            .home__title {
                order: 1;
            }

            &__prev,
            &__next {
                order: 2;
            }

            &__items {
                margin-top: 31px;
            }
        }



        //======================================================================================================
        // Kiosque + Social Wall
        //======================================================================================================
        .kiosque-wall {
            padding: 89px 0 80px;

            &__effect {
                top: 84.5%;
                right: calc(((100% - 320px) / 2) - 565px);
            }

            &__container {
                row-gap: 89px;
            }
        }


        //======================================================================================================
        // Kiosque
        //======================================================================================================
        .kiosque {
            width: 250px;
            align-items: center;

            &-item {
                flex-direction: column;
                align-items: center;
                margin-top: 37px;

                &__content {
                    align-items: center;
                    margin-top: 30px;
                }

                &__title,
                &__text {
                    text-align: center;
                }

                &__buttons {
                    flex-direction: row;
                    column-gap: 10px;
                }

                &__button {
                    &__text {
                        display: none;
                    }
                }
            }

            &__button {
                margin-top: 40px;
            }
        }

        .social-wall {
            #ff-stream-1 {            
                .ff-stream-wrapper {
                    width: 100% !important;
                    height: 870px !important;
                    flex-direction: column !important;
                    left: 0 !important;
                    row-gap: 20px !important;
                }

                .ff-item {
                    width: 100% !important;
                }
            }
        }
    }